import React from 'react'
// import { AllApi } from '../../Api/AllApi'
import { useEffect } from 'react'
import { useState } from 'react'
import { GameDB } from './../../DataBase/GameDB'
import Loading from './../../Loading'
import './../TopJoiner/TopJoiner.css'
import './../Game.css'

// import './PreviousTournament.css'


const PreviousTournament = () => {
    const [LoadingState, setLoadingState] = useState(true)
    const [PreviousLeague, setPreviousLeague] = useState(true)
    const Style = {

        Grandmaster: {
            // img: GrandMasterImg,
            Rank: '#1C1C86',
            Progress: '#0202D3',
            ListBorder: '#2B389F',
            ListTitleBg: '#2B389F',
            ListTitleText: '#FFFFFF',
            ListTitleYou: '#0202A9',
            ListTitleYouBg: '#fff',
            ListBodyBg: '#FFFFFF',
            ListBodyText: '#212121'
        },

    }

    useEffect(() => {
        GetDataFromApi()
    }, [])
    const GetDataFromApi = async () => {
        // const MakeApiClass = new AllApi();
        // let Result = await MakeApiClass.PreviousLeague(1)
        // console.log(Result)

        let PreviousLeague = await GameDB.PreviousLeague.toArray()
        let PreviousTournament = PreviousLeague[0].PreviousLeague
        console.log(PreviousTournament)
        setPreviousLeague(PreviousTournament)
        setLoadingState(false)
    }






    // console.log(LoadingState)
    return (
        // LoadingState == false ?



        PreviousLeague.topUsers ?

            <div className='Game'>
                <div className='GameContent'>

                    <div className='TopJoinerContainer'>
                        <div className='TopJoinerImagesContainer'>
                            <div className='TopJoinerDetails'>

                                <div className='TopJoinerDetailItem'>
                                    <div className='TopJoinerDetailImage'>
                                        <img src={PreviousLeague.topUsers[1].picture ? PreviousLeague.topUsers[1].picture : ''} className='UserImageTopJoinerDetailImage2' />
                                        {

                                            PreviousLeague.topUsers[1].picture ?
                                                <img className='TopJoinerDetailImageUserInnerImage2'
                                                    src={PreviousLeague.topUsers[1].picture} />
                                                :
                                                <div className='ShowFirstLetter '>
                                                    {PreviousLeague.topUsers[1].email.substring(0, 1).toUpperCase()}

                                                </div>

                                        }
                                    </div>
                                    <div className='TopJoinerDetailName '>
                                        {/* {TopjoinerState[1].nameAccount.substring(0, 9)} */}

                                        {
                                            PreviousLeague.topUsers[1].nameAccount.length > 14 ?
                                                <marquee behavior="scroll" direction="left" scrollamount="3">{PreviousLeague.topUsers[1].nameAccount}</marquee>
                                                :
                                                PreviousLeague.topUsers[1].nameAccount

                                        }


                                    </div>
                                    <div className='TopJoinerDetailJoins'>

                                        {PreviousLeague.topUsers[1].joinCount.toLocaleString()}
                                        {PreviousLeague.topUsers[1].joinCount > 1 ? <b> Joins</b> : <b> Join</b>}

                                        {/* <b>Join</b> */}
                                    </div>
                                    <div className='TopJoinerDetailGift'>
                                        <div className='TopJoinerDetailGiftImg'>
                                            {/* <img src={Gift} width={18} height={18} /> */}
                                        </div>
                                        <div className='TopJoinerDetailGiftInner'>
                                            <div className='TopJoinerDetailGiftContImg'>
                                                {/* <img src={Vector} width={8} height={60} /> */}
                                            </div>
                                            <div className='TopJoinerDetailGiftInnerItems'>
                                                {/* <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? GiftTopjoin[1].coin ? GiftTopjoin[1].coin.toLocaleString() : null : ''} <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
                                        <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? GiftTopjoin[1].xp.toLocaleString() : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div> */}
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div className='TopJoinerDetailItem CenterTopJoinerDetailItem'>
                                    <div className='TopJoinerDetailImage'>
                                        <img src={PreviousLeague.topUsers[0].picture} className='UserImageTopJoinerDetailImage' />
                                        {
                                            PreviousLeague.topUsers[0].picture ?
                                                <img className='TopJoinerDetailImageUserInnerImage'
                                                    src={PreviousLeague.topUsers[0].picture}
                                                    style={{ 'backgroundImage': `url(${PreviousLeague.topUsers[0].picture})` }}
                                                /> :
                                                <div className='ShowFirstLetter1'>
                                                    {PreviousLeague.topUsers[0].email.substring(0, 1).toUpperCase()}
                                                </div>
                                        }

                                    </div>
                                    <div className='TopJoinerDetailName '>
                                        {/* {PreviousLeague.topUsers[0].nameAccount.substring(0, 9)} */}
                                        {
                                            PreviousLeague.topUsers[0].nameAccount.length > 14 ?
                                                <marquee behavior="scroll" direction="left" scrollamount="3">{PreviousLeague.topUsers[0].nameAccount}</marquee>

                                                // <div className="scrolling-text">
                                                //   {
                                                //     // PreviousLeague.topUsers[0].nameAccount.length > 15 ?
                                                //     //   PreviousLeague.topUsers[0].nameAccount.substring(0, 15) + '...' :
                                                //     PreviousLeague.topUsers[0].nameAccount
                                                //   }
                                                // </div>
                                                :
                                                PreviousLeague.topUsers[0].nameAccount
                                        }

                                    </div>
                                    <div className='TopJoinerDetailJoins'>
                                        {PreviousLeague.topUsers[0].joinCount.toLocaleString()}
                                        {PreviousLeague.topUsers[0].joinCount > 1 ? <b> Joins</b> : <b> Join</b>}
                                        {/* <b>Join</b> */}
                                    </div>
                                    <div className='TopJoinerDetailGift'>
                                        <div className='TopJoinerDetailGiftImg'>
                                            {/* <img src={Gift} width={18} height={18} /> */}
                                        </div>
                                        <div className='TopJoinerDetailGiftInner'>
                                            <div className='TopJoinerDetailGiftContImg'>
                                                {/* <img src={Vector} width={8} height={60} /> */}
                                            </div>
                                            <div className='TopJoinerDetailGiftInnerItems'>
                                                {/* <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? GiftTopjoin[0].coin.toLocaleString() : ''} <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
                                        <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? GiftTopjoin[0].xp.toLocaleString() : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div> */}
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div className='TopJoinerDetailItem'>
                                    <div className='TopJoinerDetailImage'>
                                        {/* <img src={User3} className='UserImageTopJoinerDetailImage2' /> */}
                                        {
                                            PreviousLeague.topUsers[2].picture ?
                                                <img className='TopJoinerDetailImageUserInnerImage2'
                                                    src={PreviousLeague.topUsers[2].picture} />
                                                :
                                                <div className='ShowFirstLetter3'>
                                                    {PreviousLeague.topUsers[2].email.substring(0, 1).toUpperCase()}
                                                </div>
                                        }
                                    </div>
                                    <div className='TopJoinerDetailName '>
                                        {PreviousLeague.topUsers[2].nameAccount.length > 14 ?
                                            <marquee behavior="scroll" direction="left" scrollamount="3">{PreviousLeague.topUsers[2].nameAccount}</marquee>
                                            :
                                            PreviousLeague.topUsers[2].nameAccount
                                        }


                                    </div>
                                    <div className='TopJoinerDetailJoins'>

                                        {PreviousLeague.topUsers[2].joinCount.toLocaleString()}
                                        {PreviousLeague.topUsers[2].joinCount > 1 ? <b> Joins</b> : <b> Join</b>}

                                    </div>
                                    <div className='TopJoinerDetailGift'>
                                        <div className='TopJoinerDetailGiftImg'>
                                            {/* <img src={Gift} width={18} height={18} /> */}
                                        </div>
                                        <div className='TopJoinerDetailGiftInner'>
                                            <div className='TopJoinerDetailGiftContImg'>
                                                {/* <img src={Vector} width={8} height={60} /> */}
                                            </div>
                                            <div className='TopJoinerDetailGiftInnerItems'>
                                                {/* <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? GiftTopjoin[2].coin.toLocaleString() : ''}  <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
                                        <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? GiftTopjoin[2].xp.toLocaleString() : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div> */}
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className='MyLeagueRow4' style={{ borderColor: Style.Grandmaster.ListBorder, borderRadius: '11px', marginTop: "8px" }}>
                                <div className='MyLeagueRow4Title' style={{ backgroundColor: Style.Grandmaster.ListTitleBg, borderTopLeftRadius: '4px', borderTopRightRadius: '4px', }}>
                                    <div className='MyLeagueRow4TitleRank'>
                                        <div className='MyLeagueRow4TitleRankNumber' style={{ color: Style.Grandmaster.ListTitleText }}>

                                            {/* {IsInTop100 == 0 ? '+ 100' : '#' + IsInTop100} */}
                                            {/* {TopjoinerState.userInformation.leagueLevel} */}
                                        </div>
                                        <div className='MyLeagueRow4TitleRankUser' style={{ color: Style.Grandmaster.ListTitleText }}>
                                            {PreviousLeague.userInformation.nameAccount.length > 14 ?
                                                <marquee behavior="scroll" direction="left" scrollamount="3">{PreviousLeague.userInformation.nameAccount}</marquee>

                                                // MyUser.nameAccount.substring(0, 10) + '...' 
                                                :
                                                PreviousLeague.userInformation.nameAccount
                                            }

                                        </div>
                                        <div className='MyLeagueRow4TitleRankIsUser' style={{ color: '#2B389F', backgroundColor: Style.Grandmaster.ListTitleYouBg }}>
                                            You
                                        </div>
                                    </div>
                                    <div className='MyLeagueRow4TitleJoinRank' style={{ color: Style.Grandmaster.ListTitleText }}>
                                        {/* {JoinCountRedux.toLocaleString()}
                                {JoinCountRedux > 1 ? " Joins" : " Join"} */}


                                    </div>
                                </div>
                                <div className='MyLeagueRow4Items' style={{ backgroundColor: Style.Grandmaster.ListBodyBg, color: Style.Grandmaster.ListBodyText, border: '2px solid red', borderTop: 'none', width: 'calc(100% - 7px)', marginBottom: '1px', borderRadius: '11px', borderTopLeftRadius: '0', borderTopRightRadius: '0' }}>
                                    {
                                        PreviousLeague.topUsers ?
                                            PreviousLeague.topUsers.map((item, i) =>
                                                <div className='MyLeagueRow4Item' key={i}>
                                                    <div className='MyLeagueRow4ItemInner' style={{ color: Style.Grandmaster.ListBodyText }}>
                                                        <span className='MyLeagueRow4ItemInnerNumber' style={i == 0 ? { color: '#ECC700' } : i == 1 ? { color: '#C0C0C0' } : i == 2 ? { color: '#CD7F32' } : { color: '#212121', fontWeight: '700', fontSize: '14px' }}>{i + 1}</span>

                                                        <div className='MyLeagueRow4ItemCoulmn'>
                                                            <div className='MyLeagueRow4ItemCoulmn1'>
                                                                {

                                                                    item.nameAccount.length > 14 ?
                                                                        <marquee behavior="scroll" direction="left" scrollamount="3">{item.nameAccount}</marquee>

                                                                        :
                                                                        item.nameAccount
                                                                }
                                                                {PreviousLeague.userInformation.email == item.email ? <span className='YouBadge'>You</span> : null}</div>
                                                            <div className='MyLeagueRow4ItemCoulmn2'>{item.leagueName}</div>
                                                        </div>
                                                    </div>
                                                    <div className='MyLeagueRow4ItemInner' style={{ color: Style.Grandmaster.ListBodyText }}>
                                                        {item.joinCount.toLocaleString()}
                                                        {item.joinCount > 1 ? " Joins" : " Join"}



                                                    </div>

                                                </div>
                                            )

                                            : null
                                    }



                                </div>
                            </div>
                        </div>





                    </div>
                </div>
            </div>
            : <Loading />


    )
}

export default PreviousTournament